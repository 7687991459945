import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import { LOGS_NEW_TRY_PAGE } from '../../shared/helpers/stepsToLogs';
import { InitFactoryService } from '../../factories/initfactory.service';
import { LocalStorage } from '../../interfaces/dataStorage';
import { numberTryCount } from '../../shared/helpers/numberTry';
import { RoutesPages } from '../../interfaces/routes';
import { callRetry } from '../../shared/helpers/callback';

@Component({
  selector: 'app-new-try-root',
  templateUrl: './new-try.component.html',
  styleUrls: ['./new-try.component.scss'],
})
export class NewTryComponent {
  permitionModal: boolean = false;
  @ViewChild('elementFocus') elementFocus: ElementRef;

  constructor(
    private router: Router,
    public mainService: MainService,
    public factory: InitFactoryService,
  ) { }

  ngOnInit() {
    if (localStorage.getItem(LocalStorage.ToggleLogs) === 'true') {
      this.mainService.insightsAPI(LOGS_NEW_TRY_PAGE);
    }
    numberTryCount();
  }

  ngAfterViewInit() {
    this.elementFocus.nativeElement.focus();
  }

  startCam() {
    this.factory.startCam(true);
  }

  callHelpPage() {
    this.router.navigate([RoutesPages.Help], { queryParams: { page: RoutesPages.NewTry } });
  }

  buttonBack() {
    callRetry();
  }
}
