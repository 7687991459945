import { NgModule } from '@angular/core';
import {
  IdsAccordionModule,
  IdsActionButtonModule, IdsDirectionalButtonModule, IdsIconButtonModule, IdsIconModule, IdsLoadingModule, IdsMainButtonModule, IdsMobileHeaderModule, IdsListModule,
  IdsShimmerModule
} from '@ids/angular';
import { InitFactoryMock } from './mocks/initfactory.mock.service'

const idsModules = [
  IdsActionButtonModule,
  IdsIconModule,
  IdsIconButtonModule,
  IdsMainButtonModule,
  IdsDirectionalButtonModule,
  IdsLoadingModule,
  IdsMobileHeaderModule,
  IdsAccordionModule,
  IdsListModule,
  IdsShimmerModule
];

const mocks = [
  InitFactoryMock
];

@NgModule({
  imports: [
    idsModules
  ],
  exports: [
    idsModules
  ]
})
export class IdsModule { }