export enum LocalStorage {
  CaptureKey = 'route_token',
  CarontSessionLink = 'charon_session_link',
  SessionLink = 'session_link',
  LogsLink = 'logs_link',
  RegisterLink = 'register_link',
  CharonInsights = 'new_charon_insights',
  CharonFaceAnalyzer = 'new_charon_faceanalyzer',
  CharonEntrypoint = 'new_charon_entrypoint',
  NumberTry = 'number_try',
  ToggleLogs = 'toggle_logs',
}

export enum SessionStorage {
  Supplier = 'supplier_session',
  Token = 'token_session',
  Page = 'rout_params_page',
  CpfIdpessoa = 'rout_params_cpf_idpessoa',
  OriginProcess = 'rout_params_origin_process',
  Cn = 'cn',
  Environment = 'ambiente',
  Business = 'negocio',
  ChannelType = 'tipoDeCanal',
  PagePrefix = 'prefix_pagina',
  Tid = 'tid',
  CustomerSegment = 'customer_segment',
  RedirectUrl = 'redirectUrl',
  RedirectSuccess = 'redirectSuccess',
  RedirectError = 'redirectError',
  RedirectUrlError = 'redirectUrlError',
  RedirectUrlRetry = 'redirectUrlRetry',
  RedirectRetry = 'redirectRetry',
  ParamsUrl = 'paramsUrl',
  SetStatusOfReturn = 'setStatusOfReturn',
  UseMigratedEnvironment = 'use_migrated_environment',
  InitFacetecUserAgent = 'init_facetec_user_agent',
  EndFacetecUserAgent = 'end_facetec_user_agent'
}