export enum SuccessServices {
  SUCCESS = 'Sucess'
}

export enum QualityPage {
  SUCCESS = 'success',
  ERROR = 'error',
  QUALITY = 'quality',
}

export interface ResponseRegister {
  capturado: boolean,
  aprovado_match: boolean,
  nova_tentativa: boolean
}

export enum ServicesResponse {
  SUCCESS = 'Success',
  FAIL = 'Fail',
  DEFAULT = 'Default'
}

export interface ResponseLiveness {
  data: ResponseDataLiveness,
  links: any,
  errors: any
}

export interface ResponseDataLiveness {
  resultado: StatusFromLiveness,
  fornecedor: {
    nome: SupplierLiveness,
    parametros: any
  },
  configuracoes: any
}

export enum StatusFromLiveness {
  REJECTED = "REJECTED",
  BLOCKED = "BLOCKED",
  SUCCESS = "SUCCESS"
}

export enum SupplierLiveness {
  FACETEC = "FACETEC",
  AWARE = "AWARE"
}