import { Injectable } from '@angular/core';
import { LivenessCheckProcessor } from '../../assets/dist/facetec/FaceTecAngularSampleApp/src/processors/LivenessCheckProcessor';
import { Config } from '../../assets/dist/facetec/Config';
import { facetecLabels } from '../../assets/dist/facetec/facetec-labels';
import { SampleApp } from '../../../src/assets/dist/facetec/FaceTecAngularSampleApp/src/sampleAppController';
import { Router } from '@angular/router';
import { SessionStorage } from '../interfaces/dataStorage';
import { ErrorsPage } from '../interfaces/ErrorsPage';
import { RoutesPages } from '../interfaces/routes';
import { datadogSetErrorType } from '../shared/helpers/datadog';

@Injectable({
  providedIn: "root",
})
export class FacetecService {

  constructor(
    public router: Router
  ) { }

  token = '';
  showButton = true;
  loading: boolean = true;

  async initFacetec(facetecLicenseKey: string, dateExpiryKey: string, currentToken: string, publicKey?: string, deviceKey?: string) {
    this.token = currentToken;
    // @ts-ignore
    FaceTecSDK.setResourceDirectory('assets/dist/facetec/core-sdk/FaceTecSDK.js/resources');
    // @ts-ignore
    FaceTecSDK.setImagesDirectory('assets/dist/facetec/core-sdk/FaceTec_images');
    // @ts-ignore
    FaceTecSDK.setCustomization(Config.retrieveConfigurationWizardCustomization(FaceTecSDK));
    // @ts-ignore
    FaceTecSDK.setDynamicDimmingCustomization(Config.retrieveLowLightConfigurationWizardCustomization(FaceTecSDK));

    const keys = "{  \"domains\": \"\",  \"expiryDate\": \"" + dateExpiryKey + "\",  \"key\": \"" + facetecLicenseKey + "\"}"
    // @ts-ignore
    Config.initializeFromAutogeneratedConfig(FaceTecSDK, keys,
      (initializedSuccessfully: boolean) => {
        if (initializedSuccessfully) {
          // @ts-ignore
          FaceTecSDK.configureLocalization(facetecLabels);
          this.showButton = false
        }
        // @ts-ignore
        const status = FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus());
        console.info('$ Inicializado o módulo FaceTec; Retorno: ', status);
        if (status === 'Device/Browser not supported.') {
          this.navigateToErrorPage(ErrorsPage.INCOMPATIBLE_BROWSER);
        }
        // @ts-ignore
        const facetecUserAgent = FaceTecSDK.createFaceTecAPIUserAgentString('');
        sessionStorage.setItem(SessionStorage.InitFacetecUserAgent, facetecUserAgent);
      },
      publicKey, deviceKey
    );
  }

  private navigateToErrorPage(errorCode: string) {
    this.router.navigate([RoutesPages.Error], { queryParams: { errorCode } });
    datadogSetErrorType(errorCode);
  }

  startCam(): void {
    try {
      this.onLivenessCheckPressed();
    } catch (e) {
      console.error(e);
    }
  }

  async onLivenessCheckPressed(): Promise<void> {
    new Promise(async () => {
      let liveness = new LivenessCheckProcessor(this.token as unknown as string, SampleApp as any, this.router);
      this.loading = true;
      let startCamLoad = setInterval(() => {
        if (liveness.latestSessionResult?.status) {
          clearInterval(startCamLoad);
          this.loading = false;
        }
      }, 1000);
    });
  }
}