import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InitFactoryService } from '../../factories/initfactory.service';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { DEFAULT_PARAMS, TIPS_PAGE, TIPS_PAGE_CLICK } from '../../shared/helpers/paramsGa4';
import { ErrorsPage } from '../../interfaces/ErrorsPage';
import { verifyDeviceInfoOfAgent } from '../../shared/helpers/verification';
import { MainService } from '../../services/main.service';
import { LOGS_TIPS_PAGE } from '../../shared/helpers/stepsToLogs';
import { LocalStorage } from '../../interfaces/dataStorage';
import { datadogSetErrorType } from '../../shared/helpers/datadog';
import { RoutesPages } from '../../interfaces/routes';

@Component({
  selector: 'app-main-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  permitionModal: boolean = false;
  showLoading: boolean = false;
  showButton: boolean = true;
  showButtonBack: boolean = true;

  constructor(
    public factory: InitFactoryService,
    public router: Router,
    private route: ActivatedRoute,
    private itauDigitalAnalytics: ItauDigitalAnalyticsView,
    public mainService: MainService,
  ) { }

  async ngOnInit() {
    this.itauDigitalAnalytics.setDefaultParameters(DEFAULT_PARAMS);
    this.itauDigitalAnalytics.track(TIPS_PAGE);
    if (localStorage.getItem(LocalStorage.ToggleLogs) === 'true') {
      this.mainService.insightsAPI(LOGS_TIPS_PAGE);
    }

    if (this.route.snapshot?.queryParams?.type !== "true") {
      const hasPermision = await this.verifyCameraPermission();
      if (hasPermision) {
        this.callButtonStatus();
      }
    } else {
      this.showButton = false;
    }
    this.verifyButtonBack();
  }

  async startCam() {
    this.factory.startCam();
    this.callShowLoading();
    this.itauDigitalAnalytics.track(TIPS_PAGE_CLICK);
  }

  callShowLoading() {
    this.showLoading = true;
    let startCamLoad = setInterval(() => {
      if (!this.factory.showLoading) {
        this.showLoading = false;
        clearInterval(startCamLoad);
      }
    }, 1000);
  }

  showPermitionModal(value: boolean) {
    this.permitionModal = value;
  }

  callErrorPage() {
    this.router.navigate([RoutesPages.Error], { queryParams: { errorCode: ErrorsPage.CAMERA_CONFIG } });
    datadogSetErrorType(ErrorsPage.CAMERA_CONFIG);
  }


  async verifyCameraPermission() {
    const permissionStatus = await navigator?.permissions?.query({ name: 'camera' as PermissionName })
    const { browser } = verifyDeviceInfoOfAgent();
    if (browser === 'Safari') {
      this.showPermitionModal(true);
      return false;
    } else {
      if (permissionStatus?.state !== 'granted') {
        this.showPermitionModal(true);
        return false;
      } else {
        return true;
      }
    }
  }

  async verifyCamera() {
    if (navigator?.mediaDevices?.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          this.showPermitionModal(false);
          this.callButtonStatus();
        })
        .catch(() => {
          this.callErrorPage()
        });
    }
  }

  callHelpPage() {
    this.router.navigate([RoutesPages.Help]);
  }

  callButtonStatus() {
    let startButtonLoad = setInterval(() => {
      if (!this.factory.showButton) {
        this.showButton = false;
        clearInterval(startButtonLoad);
      }
    }, 1000);
  }

  verifyButtonBack() {
    this.showButtonBack = history.length > 1;
  }
}
