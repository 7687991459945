import { Component } from '@angular/core';
import *as labelsHelp from '../../../assets/locales/pt-br';
import { Router } from '@angular/router';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { HELP_PAGE, HELP_PAGE_CLICK } from '../../shared/helpers/paramsGa4';
import { MainService } from '../../services/main.service';
import { LOGS_HELP_PAGE } from '../../shared/helpers/stepsToLogs';
import { LocalStorage } from '../../interfaces/dataStorage';
import { RoutesPages } from '../../interfaces/routes';

@Component({
  selector: 'help-root',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  labels: any;

  constructor(
    private router: Router,
    private itauDigitalAnalytics: ItauDigitalAnalyticsView,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.labels = labelsHelp.helpPage;
    this.itauDigitalAnalytics.track(HELP_PAGE);
    if (localStorage.getItem(LocalStorage.ToggleLogs) === 'true') {
      this.mainService.insightsAPI(LOGS_HELP_PAGE);
    }
  }

  goBack() {
    this.router.navigate([RoutesPages.Main], { queryParams: { type: true }, skipLocationChange: true });
  }

  handleEvent(question: string) {
    if (!question) return;
    let formatted = question.toLowerCase();
    formatted = formatted.replace(/\s+/g, '-');
    formatted = formatted.substring(0, 100);
    const clickEvent = { ...HELP_PAGE_CLICK }
    clickEvent.analytics.parameters.detail = formatted;
    this.itauDigitalAnalytics.track(clickEvent);
  }

  openPolicy() {
    window.open("https://www.itau.com.br/seguranca/termos-de-uso", '_blank');
  }
}