import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { MainComponent } from './main/main.component';
import { Routes, RouterModule } from '@angular/router';
import { AwareComponent } from './aware/aware.component';
import { ErrorComponent } from './error/error.component';
import { SuccessComponent } from './success/success.component';
import { NewTryComponent } from './new-try/new-try.component';
import { HelpComponent } from './help/help.component';
import { QualityComponent } from './quality/quality.component';
import { MainLoadingComponent } from './main-loading/main-loading.component';
import { FacetecComponent } from './facetec/facetec.component';
import { RoutesPages } from '../interfaces/routes';

const routes: Routes = [
  { path: RoutesPages.MainLoading, component: MainLoadingComponent },
  { path: RoutesPages.Main, component: MainComponent },
  { path: RoutesPages.Error, component: ErrorComponent },
  { path: RoutesPages.Success, component: SuccessComponent },
  { path: RoutesPages.Quality, component: QualityComponent },
  { path: RoutesPages.Aware, component: AwareComponent },
  { path: RoutesPages.Facetec, component: FacetecComponent },
  { path: RoutesPages.NewTry, component: NewTryComponent },
  { path: RoutesPages.Help, component: HelpComponent },
  { path: '', redirectTo: RoutesPages.MainLoading, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [
    Location,
  ],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
