import { DatePipe } from '@angular/common';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { PayloadRegister } from '../../interfaces/Payloads'
import { getCameraInformation, getInfosFromDevice } from './verification';
import { environment } from '../../../environments/environment';
import { Supplier } from '../../interfaces/Suppliers';
import { ErrorsPage } from '../../../app/interfaces/ErrorsPage';
import { MatchContracts, ParametersContracts } from '../../../app/interfaces/Contracts';
import { LocalStorage, SessionStorage } from '../../interfaces/dataStorage';
import { datadogSetNumberTry, datadogSetToggleLogs, datadogSetUrlRedirect } from './datadog';

export const saveTokens = (supplier: string, token: string): void => {
  sessionStorage.setItem(SessionStorage.Supplier, supplier);
  sessionStorage.setItem(SessionStorage.Token, token);
}

export const saveLinksCharon = (links: any): void => {
  localStorage.setItem(LocalStorage.LogsLink, links.logs.href);
  localStorage.setItem(LocalStorage.SessionLink, links.session.href);
  localStorage.setItem(LocalStorage.RegisterLink, links.register.href);
}

export const saveLinksNewCharon = (data: any): void => {
  localStorage.setItem(LocalStorage.CharonInsights, data.body.links[0].href);
  localStorage.setItem(LocalStorage.CharonFaceAnalyzer, data.body.links[1].href);
  localStorage.setItem(LocalStorage.CharonEntrypoint, data.body.links[2].href);
}

export const saveUrlData = () => {
  const token = sessionStorage.getItem(SessionStorage.Token) || '';
  const decoded = jwtDecode(token);
  const result = decoded as any;

  const cn = sessionStorage.getItem(SessionStorage.Cn);
  const ambiente = sessionStorage.getItem(SessionStorage.Environment);
  const negocio = sessionStorage.getItem(SessionStorage.Business);
  const tipoDeCanal = sessionStorage.getItem(SessionStorage.ChannelType);
  const prefix_pagina = sessionStorage.getItem(SessionStorage.PagePrefix);
  const tid = sessionStorage.getItem(SessionStorage.Tid);
  const customer_segment = sessionStorage.getItem(SessionStorage.CustomerSegment);

  const params = '&cn=' + cn + '&ambiente=' + ambiente + '&negocio=' + negocio + '&customer_segment' + customer_segment + '&tipoDeCanal=' + tipoDeCanal + '&prefix_pagina=' + prefix_pagina + '&tid=' + tid;
  sessionStorage.setItem(SessionStorage.ParamsUrl, params);

  const cleanUrl = (url: string): string => {
    return url
      .replace('https://', '')
      .replace('http://', '')
      .replace('&&', '&')
      .replace('?&', '?');
  };

  const saveRedirectUrl = (key: string, url: string | undefined) => {
    if (url) {
      const cleanedUrl = cleanUrl(url);
      sessionStorage.setItem(key, cleanedUrl);
    }
  };

  saveRedirectUrl(SessionStorage.RedirectUrl, result.redirectUrl);
  saveRedirectUrl(SessionStorage.RedirectUrlError, result.redirectUrlError);
  saveRedirectUrl(SessionStorage.RedirectUrlRetry, result.redirectUrlRetry);

  const paramsFromURL = sessionStorage.getItem(SessionStorage.ParamsUrl);

  sessionStorage.setItem(SessionStorage.RedirectSuccess, `https://${sessionStorage.getItem(SessionStorage.RedirectUrl)}/?&type=success${paramsFromURL}`);
  sessionStorage.setItem(SessionStorage.RedirectRetry, `https://${sessionStorage.getItem(SessionStorage.RedirectUrlRetry)}/?&type=retry${paramsFromURL}`);
  sessionStorage.setItem(SessionStorage.RedirectError, `https://${sessionStorage.getItem(SessionStorage.RedirectUrlError)}/?&type=${ErrorsPage.ERROR_SERVER}${paramsFromURL}`);
  datadogSetUrlRedirect();
}

export const saveToggleReturn = (data: any): void => {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.chave == 'ou3_logs_feature_toggle' && item.valor == 'true') {
      localStorage.setItem(LocalStorage.ToggleLogs, item.valor);
      datadogSetToggleLogs();
    }
  }
}

// Necessária para o antigo Register
export const dataToRegister = () => {
  const token = sessionStorage.getItem(SessionStorage.Token) || '';
  const decoded = jwtDecode(token);
  const result = decoded as any;
  const captureKey = localStorage.getItem(LocalStorage.CaptureKey);

  let payloadRegister: PayloadRegister = {};
  payloadRegister.documentos_cliente = {};

  payloadRegister.captureKey = captureKey;

  payloadRegister.consulta_bureau = result.consultaBureau;

  payloadRegister.documentos_cliente.documento = result.sub;

  const now = new Date();
  const pipe = new DatePipe('en-US');
  const now_milliseconds = now.getMilliseconds();
  const myFormattedDate = pipe.transform(now, 'yyyy-MM-dd HH:mm:ss') || now;
  const formmatdate = myFormattedDate.toString() + '.' + now_milliseconds;
  payloadRegister.datetime_operacao = formmatdate;

  payloadRegister.modelo_dispositivo = getInfosFromDevice().device_model;
  payloadRegister.fabricante_dispositivo = getInfosFromDevice().device_fac;
  payloadRegister.sistema_operacional = getInfosFromDevice().os;
  payloadRegister.versao_sistema_operacional = getInfosFromDevice().os_version;
  payloadRegister.codigo_ipv4 = getInfosFromDevice().ipv4;

  const iHeight = window.screen.height;
  const iWidth = window.screen.width;
  payloadRegister.resolucao_camera = iWidth + ' X ' + iHeight;

  payloadRegister.versao_app_canal = '0';
  payloadRegister.versao_sdk_fornecedor = '0';
  payloadRegister.versao_sdk_mobile = '0';

  payloadRegister.id_processo_origem = sessionStorage.getItem(SessionStorage.OriginProcess);

  payloadRegister.id_sessao = uuidv4();

  payloadRegister.cod_tipo_operacao = '1',
    payloadRegister.id_tipo_captura = 2;
  payloadRegister.numero_tentativa = localStorage.getItem(LocalStorage.NumberTry) || '0';
  payloadRegister.mensagem_orientacao = [];

  payloadRegister.localizacao = {
    latitude: "00",
    longitude: "00",
    timezone: "Unknow",
    cidade: "Unknow",
    uf: "Unknow",
    pais: "Unknow"
  }

  datadogSetNumberTry();

  return payloadRegister;
}

// Necessária para o antigo Logs
export const dataToLogs = () => {
  const captureKey = localStorage.getItem(LocalStorage.CaptureKey);
  const supplier = sessionStorage.getItem(SessionStorage.Supplier);
  const versionSDK = supplier === Supplier.FACETEC ? environment.sdkFacetecVersion : environment.sdkAwareVersion;
  const { os, device_model, os_version, userAgent } = getInfosFromDevice();
  return {
    data: {
      captureKey: captureKey,
      dispositivo: {
        deviceModel: device_model,
        os: os,
        userAgent: userAgent,
        versionApp: environment.appVersion,
        versionOS: os_version,
        versionSDK: versionSDK
      },
      idJornada: 'AppSample',
      canalOrigem: sessionStorage.getItem(SessionStorage.Cn) || 'AppSample',
      steps: []
    }
  }
}

// Necessário para o NOVA Sessão (API Parameters)
export const dataToParameters = async (): Promise<ParametersContracts> => {
  const { os, os_version, browser, browserVersion } = getInfosFromDevice();
  const cam = await getCameraInformation();

  return {
    origem: 'WEB',
    resolucao_camera: cam,
    so: os,
    versao_sistema_operacional: os_version,
    web_payload: {
      navegador: browser,
      versao_navegador: browserVersion,
      versao_app: "2.0"
    }
  }
}

// Necessário para API Match
// Ainda precisa ser integrado com o backend, atualmente não temos contrato
export const dataToMatch = async (): Promise<MatchContracts> => {
  return {
    data: ''
  }
}

