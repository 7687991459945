import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionStorage } from '../../interfaces/dataStorage';
import { datadogSetNumberClickBackButton } from './datadog';

export function backButtonBlock(unsubscriber: any, callBottomSheet: () => void) {
  history.pushState(null, '');
  fromEvent(window, 'popstate').pipe(
    takeUntil(unsubscriber)
  ).subscribe((_) => {
    history.pushState(null, '');
    callBottomSheet();
    numberClickBackButton();
  });
}

function numberClickBackButton() {
  const count = localStorage.getItem(SessionStorage.NumberClickBackButton) || "0";
  localStorage.setItem(SessionStorage.NumberClickBackButton, `${parseInt(count) + 1}`);
  datadogSetNumberClickBackButton();
}