import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import { LOGS_NEW_TRY_PAGE } from '../../shared/helpers/stepsToLogs';
import { InitFactoryService } from '../../factories/initfactory.service';
import { LocalStorage } from '../../interfaces/dataStorage';
import { numberTryCount } from '../../shared/helpers/numberTry';
import { RoutesPages } from '../../interfaces/routes';
import { AnalyticsGA4Service } from '../../services/analyticsGA4.service';
import { TRY_AGAIN_CLICK_CONTINUE_AWARE, TRY_AGAIN_PAGE_AWARE, TRY_AGAIN_CLICK_HELP_AWARE, TRY_AGAIN_CLICK_BACK_AWARE } from '../../shared/helpers/paramsGa4';
import { NavigationEntryService } from '../../services/navigationEntry.service';
import { BottomSheet } from '../../shared/components/bottom-sheet/bottom-sheet.component';
import { verifyDeviceInfoOfAgent } from '../../shared/helpers/verification';
import { Subject } from 'rxjs';
import { backButtonBlock } from '../../shared/helpers/backButtonBrowser';

@Component({
  selector: 'app-new-try-root',
  templateUrl: './new-try.component.html',
  styleUrls: ['./new-try.component.scss'],
})
export class NewTryComponent {
  permitionModal: boolean = false;
  routePage = RoutesPages.NewTry;
  @ViewChild('elementFocus') elementFocus: ElementRef;
  @ViewChild(BottomSheet) BottomSheet: any;
  private unsubscriber: Subject<void> = new Subject<void>();
  BottomSheetCall = {
    callBottomSheet: () => {
      this.BottomSheet.callBottomSheet();
    }
  };

  constructor(
    private router: Router,
    public mainService: MainService,
    public factory: InitFactoryService,
    public navigationEntry: NavigationEntryService,
    private analyticsGA4Service: AnalyticsGA4Service,
  ) { }

  ngOnInit() {
    this.navigationEntry.verifyNavigationEntry();
    backButtonBlock(this.unsubscriber, this.BottomSheetCall.callBottomSheet.bind(this));
    if (localStorage.getItem(LocalStorage.ToggleLogs) === 'true') {
      this.mainService.insightsAPI(LOGS_NEW_TRY_PAGE);
    }
    numberTryCount();
    this.analyticsGA4Service.trackEventGA4(TRY_AGAIN_PAGE_AWARE);
  }

  ngAfterViewInit() {
    this.elementFocus.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  startCam() {
    this.factory.startCam(true);
    this.analyticsGA4Service.trackEventGA4(TRY_AGAIN_CLICK_CONTINUE_AWARE);
  }

  callHelpPage() {
    this.router.navigate([RoutesPages.Help], { queryParams: { page: RoutesPages.NewTry } });
    this.analyticsGA4Service.trackEventGA4(TRY_AGAIN_CLICK_HELP_AWARE);
  }

  buttonBack() {
    this.BottomSheet.callBottomSheet();
  }

  verifyIsSafari() {
    const { browser } = verifyDeviceInfoOfAgent();
    return browser === 'Safari';
  }
}
