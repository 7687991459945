import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EndFactoryService } from '../../factories/endfactory.service';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { AWARE_PAGE } from '../../shared/helpers/paramsGa4';
import { firstTry } from '../../shared/helpers/numberTry';

declare function initApp();

@Component({
  selector: 'app-aware-root',
  templateUrl: './aware.component.html',
  styleUrls: ['./aware.component.scss'],
})
export class AwareComponent {
  initPage: boolean = true;
  finishPageOne: boolean = false;
  finishPageTwo: boolean = false;
  elementRef: ElementRef;

  constructor(
    public factory: EndFactoryService,
    public route: ActivatedRoute,
    private itauDigitalAnalytics: ItauDigitalAnalyticsView
  ) { }

  ngOnInit() {
    this.adjustFormatScreenInit();
    if (this.route?.snapshot?.queryParams?.restart) {
      initApp();
    }
    setTimeout(() => {
      this.initPage = false
    }, 5000);
    this.itauDigitalAnalytics.track(AWARE_PAGE);
    firstTry();
  }

  ngAfterViewInit() {
    this.monitoringElement();
    // this.monitoringResult();
  }

  ngOnDestroy(): void {
    this.adjustFormatScreenEnd();
  }

  monitoringElement() {
    const element = document.getElementById('speech-progress');
    if (element) {
      this.updateVisibility(element);

      const observer = new MutationObserver(() => {
        this.updateVisibility(element);
      })

      observer.observe(element, { attributes: true, attributeFilter: ['aria-valuenow'] });
    }
  }

  // PROVAVELMENTE NAO SERÄ MAIS NECESSARIO PELO USO Do this.factory.resolveFactory(JSON.parse(res)); NO NEW CHARON

  // monitoringResult() {
  //   const element = document.getElementById('frontImage');
  //   if (element) {
  //     const observer = new MutationObserver(() => {
  //       this.callResolve(element)
  //     })
  //     observer.observe(element, { attributes: true, attributeFilter: ['aria-response'] });
  //   }
  // }

  // callResolve(element: HTMLElement) {
  //   const result = element.getAttribute('aria-response');
  //   if(result) {
  //     setTimeout(() => {
  //       this.factory.resolveFactory(JSON.parse(result));
  //     }, 5000);
  //   }
  // }

  updateVisibility(element: HTMLElement) {
    const finishPageOne = element.getAttribute('aria-valuenow');
    if (finishPageOne) {
      if (parseInt(finishPageOne, 10) === 100) {
        this.finishPageOne = true;
        setTimeout(() => {
          this.finishPageOne = false;
          this.finishPageTwo = true;
        }, 2000);
      }
    }
  }

  adjustFormatScreenInit() {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  }

  adjustFormatScreenEnd() {
    document.body.style.overflow = 'initial';
    document.body.style.position = 'initial';
    document.body.style.width = 'initial';
  }

}
