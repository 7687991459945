import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { Segments } from '@ids/tools';
import { ItauSdkCoreAnalyticsModule } from '@app-analytics/analytics';
import { PagesModule } from './pages/pages.module';
import { HttpClientModule } from '@angular/common/http';
import { IdsConfigModule } from '@ids/angular';
import type { FaceTecSDK as FaceTecSDKType } from "../assets/dist/facetec/core-sdk/FaceTecSDK.js/FaceTecSDK"
import { environment } from '../environments/environment';
import { CharonHttpService, CharonModule } from '@quickweb/connect-angular';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    IdsConfigModule.forRoot({
      production: environment.isProd,
      segment: Segments.Varejo
    }),
    ItauSdkCoreAnalyticsModule,
    PagesModule,
    CharonModule.withConfig({
      httpOptions: {},
      payloadCryptography: {
        initSessionUrl: environment.newCharonUrl,
      },
      provider: CharonHttpService,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

// used on sample-app of facetec use
declare global {
  const FaceTecSDK: typeof FaceTecSDKType;
}