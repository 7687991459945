import { LocalStorage } from "../../interfaces/dataStorage";
import { datadogSetNumberTry } from "./datadog";

export const firstTry = () => {
  const numberTry = localStorage.getItem(LocalStorage.NumberTry);
  if (!numberTry) {
    localStorage.setItem(LocalStorage.NumberTry, "1");
  }
  datadogSetNumberTry();
}

export const numberTryCount = () => {
  const count = localStorage.getItem(LocalStorage.NumberTry) || "1";
  localStorage.setItem(LocalStorage.NumberTry, `${parseInt(count) + 1}`);
  datadogSetNumberTry();
}