import { SessionStorage } from '../../interfaces/dataStorage';
import { dataStorageClean } from './dataStorage';
import { RoutesPages } from '../../interfaces/routes';

const redirectTo = (urlKey: string, redirectKey: string) => {
  const url = sessionStorage.getItem(urlKey);
  const redirect = sessionStorage.getItem(redirectKey);
  window.document.location.href = url ? redirect || RoutesPages.UrlCallBackDefault : RoutesPages.UrlCallBackDefault;
  dataStorageClean();
};

export const callSuccess = () => {
  redirectTo(SessionStorage.RedirectUrl, SessionStorage.RedirectSuccess);
};

export const callError = () => {
  redirectTo(SessionStorage.RedirectUrlError, SessionStorage.RedirectError);
};

export const callRetry = () => {
  const urlRetry = sessionStorage.getItem(SessionStorage.RedirectUrlRetry);
  const urlError = sessionStorage.getItem(SessionStorage.RedirectUrlError);

  if (urlRetry) {
    redirectTo(SessionStorage.RedirectUrlRetry, SessionStorage.RedirectRetry);
  } else if (urlError) {
    redirectTo(SessionStorage.RedirectUrlError, SessionStorage.RedirectError);
  } else {
    window.document.location.href = RoutesPages.UrlCallBackDefault;
  }
  dataStorageClean();
};