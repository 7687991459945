<div class="container-page">
  <loading *ngIf="this.loading" class="loading-page" icon="maquina_fotografica_outline"
    label="Validando suas informações" [showFooter]="true">
  </loading>
  <loading *ngIf="this.loadingTwo" class="loading-page" icon="maquina_fotografica_outline"
    label="Com a validação, sua conta fica mais segura" [showFooter]="true">
  </loading>
  <loading *ngIf="this.loadingFinish" class="loading-page" icon="check" label="Identidade confirmada!"
    [showFooter]="true">
  </loading>
</div>