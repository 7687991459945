import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorsPage } from '../../interfaces/ErrorsPage';
import { verifyUrlOfAgent } from '../../../app/shared/helpers/verification';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { ERROR_BLOCKED, ERROR_CAM_NOT_RESPOND_PAGE, ERROR_CAM_PERMISSION_PAGE, ERROR_GENERIC, ERROR_LIMIT_EXCEED } from '../../shared/helpers/paramsGa4';
import { MainService } from '../../services/main.service';
import { LOGS_ERROR_PAGE_BLOCK, LOGS_ERROR_PAGE_GENERIC, LOGS_ERROR_PAGE_NO_RESPOSE, LOGS_ERROR_PAGE_PERMISSION, LOGS_ERROR_PAGE_TRIES } from '../../shared/helpers/stepsToLogs';
import { dataStorageClean } from '../../shared/helpers/dataStorage';
import { LocalStorage, SessionStorage } from '../../interfaces/dataStorage';
import { datadogSetErrorType } from '../../shared/helpers/datadog';

const ERROR_URL = 'www.itau.com.br/';
const DEFAULT_ERROR = {
  title: 'Desculpe, algo deu errado',
  icon: 'aviso_outline',
  description: 'Não foi possível carregar as informações neste momento. Por favor, tente outra vez',
  button: 'Fechar',
  track: ERROR_GENERIC,
  log: LOGS_ERROR_PAGE_GENERIC
};

@Component({
  selector: 'error-root',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  title: string;
  description: string;
  button: string;
  icon: string;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private itauDigitalAnalytics: ItauDigitalAnalyticsView,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.handleRoute();
    this.setRedirectUrlError();
  }

  handleRoute() {
    const toggle = localStorage.getItem(LocalStorage.ToggleLogs) === 'true';
    const errorCode = this.route.snapshot?.queryParams?.errorCode;
    const errorConfig = this.getErrorConfig(errorCode);

    this.title = errorConfig.title;
    this.icon = errorConfig.icon;
    this.description = errorConfig.description;
    this.button = errorConfig.button;
    this.itauDigitalAnalytics.track(errorConfig.track);

    if (toggle) {
      this.mainService.insightsAPI(errorConfig.log);
    }
  }

  getErrorConfig(errorCode: string) {
    switch (errorCode) {
      case ErrorsPage.CAMERA_CONFIG:
        return {
          title: 'Autorize o acesso à câmera para confirmar sua identidade',
          icon: 'aviso_outline',
          description: 'Por favor, autorize o acesso à câmera para continuar com o reconhecimento facial',
          button: 'Abrir configurações',
          track: ERROR_CAM_PERMISSION_PAGE,
          log: LOGS_ERROR_PAGE_PERMISSION
        };
      case ErrorsPage.CAMERA_ERROR:
        return {
          title: 'A câmera não respondeu, por isso não conseguimos fazer seu reconhecimento facial',
          icon: 'maquina_fotografica_outline',
          description: 'Sem sua câmera, não é possível tirar sua foto para o reconhecimento facial',
          button: 'Fechar',
          track: ERROR_CAM_NOT_RESPOND_PAGE,
          log: LOGS_ERROR_PAGE_NO_RESPOSE
        };
      case ErrorsPage.LIMIT_ERROR:
        return {
          title: 'Você atingiu o limite de tentativas de captura',
          icon: 'aviso_outline',
          description: 'Por segurança, não conseguimos seguir com a solicitação sem confirmar sua identidade. Tente novamente mais tarde',
          button: 'Fechar',
          track: ERROR_LIMIT_EXCEED,
          log: LOGS_ERROR_PAGE_TRIES
        };
      case ErrorsPage.LIMIT_BLOCK:
        return {
          title: 'Você atingiu o limite de tentativas de captura',
          icon: 'aviso_outline',
          description: 'Por segurança, não conseguimos seguir com a solicitação sem confirmar sua identidade. Tente novamente amanhã',
          button: 'Fechar',
          track: ERROR_BLOCKED,
          log: LOGS_ERROR_PAGE_BLOCK
        };
      case ErrorsPage.FAIL_KEY:
        return {
          title: 'O tempo para tirar sua foto acabou',
          icon: 'aviso_outline',
          description: 'Por segurança, não conseguimos seguir com a solicitação. Tente novamente mais tarde',
          button: 'Fechar',
          track: ERROR_GENERIC,
          log: LOGS_ERROR_PAGE_GENERIC
        };
      case ErrorsPage.ERROR_SERVER:
        return DEFAULT_ERROR;
      default:
        return DEFAULT_ERROR;
    }
  }

  handleButton() {
    if (this.route.snapshot?.queryParams?.errorCode === ErrorsPage.CAMERA_CONFIG) {
      window.open(verifyUrlOfAgent(), '_blank');
    } else {
      this.callRedirect();
    }
  }

  callRedirect() {
    const params = sessionStorage.getItem(SessionStorage.ParamsUrl);
    const errorCode = this.route.snapshot?.queryParams?.errorCode;
    const type = this.getRedirectType(errorCode);

    window.document.location.href = `https://${sessionStorage.getItem(SessionStorage.RedirectUrlError)}/?&type=${type}${params}`;
    dataStorageClean();
  }

  getRedirectType(errorCode: string): string {
    switch (errorCode) {
      case ErrorsPage.CAMERA_CONFIG:
      case ErrorsPage.CAMERA_ERROR:
        return 'error_no_permission';
      case ErrorsPage.LIMIT_ERROR:
      case ErrorsPage.LIMIT_BLOCK:
        return 'error_no_frame';
      case ErrorsPage.FAIL_KEY:
      case ErrorsPage.ERROR_SERVER:
        return 'no_session';
      default:
        return 'unknown_error';
    }
  }

  setRedirectUrlError() {
    const url = sessionStorage.getItem(SessionStorage.RedirectUrlError);
    if (!url) {
      sessionStorage.setItem(SessionStorage.RedirectUrlError, ERROR_URL);
    }
  }
}