export enum RoutesPages {
  Main = 'cam',
  Error = 'capture/error',
  Success = 'capture/success',
  Quality = 'capture/quality',
  Aware = 'capture/aware',
  Facetec = 'capture/facetec',
  NewTry = 'capture/new_try',
  Help = 'capture/help',
  UrlCallBackDefault = 'https://www.itau.com.br'
}