export enum ErrorsServices {
  CarontFail = 'Get from Caron failing, error: ',
  SendParamsFail = 'Error to send Params, error: ',
  RegisterFail = 'Error to register new user, error: ',
  LogsFail = 'Error to send logs of user, error: ',
  GetIpFail = 'Error to get the IP, error: ',
}

export interface ResponseError {
  error: ErrorMessage
  headers: any
  message: string
  name: string
  ok: boolean
  status: number
  statusText: string
  url: string
}

export interface ErrorMessage {
  message: TypesMessages
}

export enum TypesMessages {
  SUCCESS = 'SUCCESS',
  CW_ERRO_CREATE_SESSION_HTTP_404 = 'CW_ERRO_CREATE_SESSION_HTTP_404'
}