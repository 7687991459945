import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DEFAULT_PARAMS, DEFAULT_CONFIG } from '../shared/helpers/paramsGa4';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsGA4Service {

  private ItauDigitalAnalytics: any;
  private SDKAnalytics: Promise<any>;

  constructor() { }

  getSDK(url: string) {
    const script = document.createElement('script');

    return new Promise((resolve, reject) => {
      script.src = url;
      script.async = true;
      script.onload = () => resolve(window['SDKAnalytics']);
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  async initGA4Sdk() {
     this.SDKAnalytics = this.getSDK(environment.urlSdkGA4).then((SDKAnalytics: any) => {
        this.ItauDigitalAnalytics = SDKAnalytics(DEFAULT_CONFIG);
     });
    
    return this.SDKAnalytics;
  }

  async trackEventGA4(data: any) {
    await this.SDKAnalytics;
    this.ItauDigitalAnalytics?.setDefaultParameters(DEFAULT_PARAMS);
    this.ItauDigitalAnalytics?.track(data);
  }
}