<div>
	<ids-mobile-header [scrollElement]="true">
		<button id="back-button" idsMobileHeaderAction idsIconButton variant="neutral" title="voltar" aria-label="voltar"
			(click)="goBack()">
			seta_esquerda
		</button>
	</ids-mobile-header>
	<div class="ids-px-6">
		<h1 class="ids-title -bold ids-py-6" tabindex="-1" id="main-title" #elementFocus>Dúvidas frequentes</h1>
		<div class="ids-pl-2 content-accordion ids-pt-6">
			<ids-accordion *ngFor="let label of labels" class="ids-accordion-header" header={{label.question}}
				(click)="handleEvent(label.question)">
				<p class="ids-accordion-panel">{{label.answer}}</p>
			</ids-accordion>
		</div>
	</div>
	<div class="container-footer" (click)="openPolicy()">
		<ids-icon class="content-footer">documento_outline</ids-icon>
		<div class="content-footer">Consultar política de privacidade</div>
	</div>
</div>