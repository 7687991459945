import { Component, Input } from '@angular/core';
import { RoutesPages } from '../../../interfaces/routes';
import { callRetry } from '../../helpers/callback';
import { AnalyticsGA4Service } from '../../../services/analyticsGA4.service';
import { TIPS_PAGE_CLICK_BACK } from '../../../shared/helpers/paramsGa4';
import { FACETEC_CLICK_CLOSE, AWARE_CLICK_CLOSE, TRY_AGAIN_CLICK_BACK_AWARE, ERROR_GENERIC_CLICK_CLOSE } from '../../helpers/paramsGa4';
import { datadogSetAbandonPage } from '../../../shared/helpers/datadog';

@Component({
  selector: 'bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheet {
  @Input() pageRoute: string;
  isOpenBottomSheet = false;

  constructor(
    private analyticsGA4Service: AnalyticsGA4Service,
  ) { }

  callBottomSheet() {
    this.isOpenBottomSheet = !this.isOpenBottomSheet;
  }

  callToGoOut() {
    switch (this.pageRoute) {
      case RoutesPages.Main:
        this.analyticsGA4Service.trackEventGA4(TIPS_PAGE_CLICK_BACK);
        datadogSetAbandonPage(RoutesPages.Main);
        callRetry();
        break;
      case RoutesPages.Facetec:
        this.analyticsGA4Service.trackEventGA4(FACETEC_CLICK_CLOSE);
        datadogSetAbandonPage(RoutesPages.Facetec);
        callRetry();
        break;
      case RoutesPages.Aware:
        this.analyticsGA4Service.trackEventGA4(AWARE_CLICK_CLOSE);
        datadogSetAbandonPage(RoutesPages.Aware);
        callRetry();
        break;
      case RoutesPages.NewTry:
        this.analyticsGA4Service.trackEventGA4(TRY_AGAIN_CLICK_BACK_AWARE);
        datadogSetAbandonPage(RoutesPages.NewTry);
        callRetry();
        break;
      case RoutesPages.Error:
        this.analyticsGA4Service.trackEventGA4(ERROR_GENERIC_CLICK_CLOSE);
        datadogSetAbandonPage(RoutesPages.Error);
        callRetry();
        break;
      default:
        callRetry();
    }
  }
}
