import { Config } from "../../Config";
import { LivenessCheckProcessor } from "./processors/LivenessCheckProcessor";
import { EnrollmentProcessor } from "./processors/EnrollmentProcessor";
import { VerificationProcessor } from "./processors/VerificationProcessor";
import { SampleAppUtilities } from "./utilities/SampleAppUtilities";
import { PhotoIDMatchProcessor } from "./processors/PhotoIDMatchProcessor";
import { PhotoIDScanProcessor } from "./processors/PhotoIDScanProcessor";
import { ThemeHelpers } from "./utilities/ThemeHelpers";
import { FaceTecSessionResult, FaceTecIDScanResult } from "../../core-sdk/FaceTecSDK.js/FaceTecPublicApi";
import { ClearLatestEnrollmentIdentifier, GetLatestEnrollmentIdentifier, OnComplete } from "./sampleAppControllerReference/SampleAppControllerReference";

export var SampleApp = ((): any => {
  var latestEnrollmentIdentifier = "";
  var latestProcessor: LivenessCheckProcessor | EnrollmentProcessor | VerificationProcessor | PhotoIDMatchProcessor | PhotoIDScanProcessor;
  var latestSessionResult: FaceTecSessionResult | null = null;
  var latestIDScanResult: FaceTecIDScanResult | null = null;

  // Wait for onload to be complete before attempting to access the Browser SDK.
  // window.onload = (): void => {
  //   SampleAppUtilities.formatUIForDevice();

  //   // Set a the directory path for other FaceTec Browser SDK Resources.
  //   FaceTecSDK.setResourceDirectory("/assets/core-sdk/FaceTecSDK.js/resources");

  //   // Set the directory path for required FaceTec Browser SDK images.
  //   FaceTecSDK.setImagesDirectory("/assets/core-sdk/FaceTec_images");

  //   // Set your FaceTec Device SDK Customizations.
  //   ThemeHelpers.setAppTheme(ThemeHelpers.getCurrentTheme());

  //   // Initialize FaceTec Browser SDK and configure the UI features.
  //   Config.initializeFromAutogeneratedConfig(FaceTecSDK, function(initializedSuccessfully: boolean) {
  //     if(initializedSuccessfully) {
  //       SampleAppUtilities.enableControlButtons();

  //       // Set the sound files that are to be used for Vocal Guidance.
  //       SampleAppUtilities.setVocalGuidanceSoundFiles();
  //     }

  //     SampleAppUtilities.displayStatus(FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()));
  //   });

  //   SampleAppUtilities.fadeInMainUIContainer();
  // };

  // Clear previous session results;
  function initializeResultObjects(): void {
    latestSessionResult = null;
    latestIDScanResult = null;
  }

  // Initiate a 3D Liveness Check.
  function onLivenessCheckPressed(): void {
    initializeResultObjects();
    SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

    // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.
    getSessionToken((sessionToken?: string): void => {
      latestProcessor = new LivenessCheckProcessor(sessionToken as string, SampleApp as any);
    });
  }

  // Initiate a 3D Liveness Check, then storing the 3D FaceMap in the Database, also known as "Enrollment".  A random enrollmentIdentifier is generated each time to guarantee uniqueness.
  function onEnrollUserPressed(): void {
    initializeResultObjects();
    SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

    // Get a Session Token from the FaceTec SDK, then start the Enrollment.
    getSessionToken((sessionToken?: string) => {
      latestEnrollmentIdentifier = "browser_sample_app_" + SampleAppUtilities.generateUUId();
      latestProcessor = new EnrollmentProcessor(sessionToken as string, SampleApp as any);
    });
  }

  // Perform 3D to 3D Verification against the Enrollment previously performed.
  function onVerifyUserPressed(): void {
    initializeResultObjects();

    // For demonstration purposes, verify that we have an enrollmentIdentifier to Verify against.
    if(latestEnrollmentIdentifier.length === 0) {
      SampleAppUtilities.displayStatus("Please enroll first before trying verification.");
    }
    else {
      SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

      // Get a Session Token from the FaceTec SDK, then start the 3D to 3D Matching.
      getSessionToken((sessionToken?: string): void => {
        latestProcessor = new VerificationProcessor(sessionToken as string, SampleApp as any);
      });
    }
  }

  // Perform a 3D Liveness Check, then an ID Scan, then Match the 3D FaceMap to the ID Scan.
  function onPhotoIDMatchPressed(): void {
    initializeResultObjects();
    SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

    // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.  On Success, ID Scanning will start automatically.
    getSessionToken((sessionToken?: string): void => {
      latestEnrollmentIdentifier = "browser_sample_app_" + SampleAppUtilities.generateUUId();
      latestProcessor = new PhotoIDMatchProcessor(sessionToken as string, SampleApp as any);
    });
  }

  // Perform Photo ID Scan, generating a username each time to guarantee uniqueness.
  function onPhotoIDScanPressed(): void {
    initializeResultObjects();
    SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

    // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.  On Success, ID Scanning will start automatically.
    getSessionToken(function(sessionToken?: string) {
      latestProcessor = new PhotoIDScanProcessor(sessionToken as string, SampleApp as any);
    });
  }

  // Show the final result with the Session Review Screen.
  var onComplete: OnComplete;

  onComplete = (sessionResult: FaceTecSessionResult | null, idScanResult: FaceTecIDScanResult | null, latestNetworkResponseStatus: number): void => {
    latestSessionResult = sessionResult;
    latestIDScanResult = idScanResult;

    // if(!latestProcessor.isSuccess()) {
    //   // Check for server offline
    //   if(isNetworkResponseServerIsOffline(latestNetworkResponseStatus) === true) {
    //     showAdditionalScreensServerIsDown();
    //     return;
    //   }
    // }

    SampleAppUtilities.displayStatus("See logs for more details.");
    SampleAppUtilities.showMainUI();
  };

  // Check for server down status
  function isNetworkResponseServerIsOffline(networkResponseStatus: number): boolean {
    return (networkResponseStatus >= 500);
  }

  // Set a new customization for FaceTec Browser SDK.
  function onDesignShowcasePressed(): void {
    ThemeHelpers.showNewTheme();
  }

  function onVocalGuidanceSettingsButtonPressed(): void {
    SampleAppUtilities.setVocalGuidanceMode();
  }

  // Display audit trail images captured from user's last FaceTec Browser SDK Session (if available).
  function onViewAuditTrailPressed(): void {
    SampleAppUtilities.showAuditTrailImages(latestSessionResult, latestIDScanResult);
  }

  let sessionTokenErrorHasBeenHandled = false;

  function onSessionTokenError(xhrStatus: number | undefined): void {
    if(sessionTokenErrorHasBeenHandled === false) {
      sessionTokenErrorHasBeenHandled = true;

      if(xhrStatus !== undefined && isNetworkResponseServerIsOffline(xhrStatus)) {
        showAdditionalScreensServerIsDown();
      }
      else {
        onServerSessionTokenError();
      }
    }
  }

  // Get the Session Token from the server
  function getSessionToken(sessionTokenCallback: (sessionToken: string) => void): void {
    sessionTokenErrorHasBeenHandled = false;

    try {
      var XHR = new XMLHttpRequest();
      XHR.open("GET", Config.BaseURL + "/session-token");
      XHR.setRequestHeader("X-Device-Key", Config.DeviceKeyIdentifier);
      console.info("Config.CredentialUser = " + Config.CredentialUser);
      XHR.setRequestHeader("Authorization", Config.CredentialUser);
      // @ts-ignore
      XHR.setRequestHeader("X-User-Agent", FaceTecSDK.createFaceTecAPIUserAgentString(""));

      XHR.onreadystatechange = function(): void {
        if(this.readyState === XMLHttpRequest.DONE) {
          var sessionToken = "";

          try {
            // Attempt to get the sessionToken from the response object.
            sessionToken = JSON.parse(this.responseText).sessionToken;

            // Something went wrong in parsing the response. Return an error.
            if(typeof sessionToken !== "string") {
              onSessionTokenError(XHR.status);
              return;
            }
          }
          catch{
            // Something went wrong in parsing the response. Return an error.
            XHR.abort();
            onSessionTokenError(XHR.status);
            return;
          }

          SampleAppUtilities.hideLoadingSessionToken();
          sessionTokenCallback(sessionToken);
        }
      };

      // Wait 3s, if the request is not completed yet, show the session token loading screen
      window.setTimeout(() => {
        if(XHR.readyState !== XMLHttpRequest.DONE) {
          if(sessionTokenErrorHasBeenHandled === false) {
            SampleAppUtilities.showLoadingSessionToken();
          }
        }
      }, 3000);

      XHR.onerror = function(): void {
        XHR.abort();
        onSessionTokenError(XHR.status);
      };

      XHR.send();
    }
    catch(e) {
      onSessionTokenError(undefined);
    }
  }

  function showAdditionalScreensServerIsDown(): void {
    SampleAppUtilities.displayStatus("Server upgrade in progress. Please try again later");
  }

  function onServerSessionTokenError(): void {
    SampleAppUtilities.handleErrorGettingServerSessionToken();
  }

  var getLatestEnrollmentIdentifier: GetLatestEnrollmentIdentifier = (): string => {
    return latestEnrollmentIdentifier;
  };

  var clearLatestEnrollmentIdentifier: ClearLatestEnrollmentIdentifier = () => {
    latestEnrollmentIdentifier = "";
  };

  return {
    onLivenessCheckPressed,
    onEnrollUserPressed,
    onVerifyUserPressed,
    onPhotoIDMatchPressed,
    onPhotoIDScanPressed,
    onDesignShowcasePressed,
    onComplete,
    getLatestEnrollmentIdentifier,
    clearLatestEnrollmentIdentifier,
    onVocalGuidanceSettingsButtonPressed,
    onViewAuditTrailPressed,
    latestSessionResult,
    latestIDScanResult
  };
})();
