import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InitFactoryService } from '../../factories/initfactory.service';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { DEFAULT_PARAMS, TIPS_PAGE, TIPS_PAGE_CLICK, LOADING_FIRST } from '../../shared/helpers/paramsGa4';
import { ErrorsPage } from '../../interfaces/ErrorsPage';
import { MainService } from '../../services/main.service';
import { LOGS_TIPS_PAGE } from '../../shared/helpers/stepsToLogs';
import { LocalStorage } from '../../interfaces/dataStorage';
import { datadogSetErrorType } from '../../shared/helpers/datadog';
import { RoutesPages } from '../../interfaces/routes';
import { TypesMessages } from '../../interfaces/ErrorsServices';
import { verifyDeviceInfoOfAgent } from '../../shared/helpers/verification';

@Component({
  selector: 'app-main-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  showLoading: boolean = false;
  showButtonBack: boolean = true;
  showButton: boolean = true;
  showShimmer: boolean = true;
  isVerifyCamera: boolean = false;
  @ViewChild('mainElementFocus') mainElementFocus: ElementRef;

  constructor(
    public factory: InitFactoryService,
    public router: Router,
    private itauDigitalAnalytics: ItauDigitalAnalyticsView,
    public mainService: MainService,
    public route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    const isNavigationReturn: boolean = this.route.snapshot?.queryParams?.type || false;

    if (isNavigationReturn) {
      this.showShimmer = false;
      this.callButtonStatus();
      this.verifyButtonBack();
      return;
    }

    this.init();
  }

  async init() {
    this.showShimmer = true;
    this.itauDigitalAnalytics.track(LOADING_FIRST);
    const inicialization = await this.factory.initializeFactory();

    if (inicialization !== TypesMessages.SUCCESS) {
      this.router.navigate([RoutesPages.Error], { queryParams: { errorCode: ErrorsPage.FAIL_KEY } });
      datadogSetErrorType(ErrorsPage.FAIL_KEY);
    } else {
      this.showShimmer = false;
      this.itauDigitalAnalytics.setDefaultParameters(DEFAULT_PARAMS);
      this.itauDigitalAnalytics.track(TIPS_PAGE);
      if (localStorage.getItem(LocalStorage.ToggleLogs) === 'true') {
        this.mainService.insightsAPI(LOGS_TIPS_PAGE);
      }
      this.callButtonStatus();
      this.verifyButtonBack();
      setTimeout(() => {
        this.mainElementFocus.nativeElement.focus();
      }, 1000);
    }
  }



  async startCam() {
    this.showLoading = true;
    const hasPermision = await this.verifyCameraPermission();
    if (hasPermision) {
      this.startCamLoading();
    } else {
      this.verifyCamera();
    }
  }

  startCamLoading() {
    let startCamLoad = setInterval(() => {
      if (!this.factory.showLoading) {
        this.itauDigitalAnalytics.track(TIPS_PAGE_CLICK);
        clearInterval(startCamLoad);
        this.showLoading = false;
        this.factory.startCam();
      }
    }, 1000);
  }

  cameraError() {
    this.router.navigate([RoutesPages.Error], { queryParams: { errorCode: ErrorsPage.CAMERA_CONFIG } });
    datadogSetErrorType(ErrorsPage.CAMERA_CONFIG);
  }

  otherbrowser() {
    this.router.navigate([RoutesPages.Error], { queryParams: { errorCode: ErrorsPage.CAMERA_ERROR } });
    datadogSetErrorType(ErrorsPage.INCOMPATIBLE_BROWSER);
  }

  async verifyCameraPermission() {
    const permissionStatus = await navigator?.permissions?.query({ name: 'camera' as PermissionName });
    switch (permissionStatus?.state) {
      case 'granted':
        return true;
      case 'prompt':
        return false;
      case 'denied':
        return false;
      case undefined:
        this.otherbrowser();
      default:
        return false;
    }
  }

  async verifyCamera() {
    if (navigator?.mediaDevices?.getUserMedia) {
      await navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          var track = stream.getTracks()[0];
          track.stop();
          this.startCam();
        })
        .catch(() => {
          this.cameraError();
        });
    }
  }

  callHelpPage() {
    this.router.navigate([RoutesPages.Help], { queryParams: { page: RoutesPages.Main } });
  }

  callButtonStatus() {
    let startButtonLoad = setInterval(() => {
      if (!this.factory.showButton) {
        this.showButton = false;
        clearInterval(startButtonLoad);
      }
    }, 1000);
  }

  verifyButtonBack() {
    this.showButtonBack = history.length > 1;
  }

  verifyIsSafari() {
    const { browser } = verifyDeviceInfoOfAgent();
    return browser === 'Safari';
  }
}
