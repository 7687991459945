import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class Loading {
  @Input() icon: string;
  @Input() label: string;
  @Input() showFooter: boolean;
  @ViewChild('loadingFocus') loadingFocus: ElementRef;

  ngAfterViewInit() {
    this.loadingFocus.nativeElement.focus();
  }
}
