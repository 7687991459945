

export enum ErrorsPage {
  CAMERA_CONFIG = 'camera_config',
  CAMERA_ERROR = 'camera_error',
  LIMIT_ERROR = 'limit_error',
  LIMIT_BLOCK = 'limit_block',
  FAIL_KEY = 'fail_key',
  ERROR_SERVER = 'error_server',
  INCOMPATIBLE_BROWSER = 'incompatible_browser'
}