import { Component } from '@angular/core';
import { firstTry } from '../../shared/helpers/numberTry';

@Component({
  selector: 'app-facetec',
  templateUrl: './facetec.component.html',
})
export class FacetecComponent {
  constructor(
  ) { }

  ngOnInit() {
    firstTry();
  }

  ngOnDestroy() {
    const facetecComponent = document.getElementById("DOM_FT_PRIMARY_TOPLEVEL_mainContainer")
    if (facetecComponent) {
      facetecComponent!.remove();
    }
  }
}