import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EndFactoryService } from '../../factories/endfactory.service';
import { AWARE_PAGE, AWARE_CLICK_TAKE_PICTURE, LOADING_MEASURING, LOADING_ANALYSE } from '../../shared/helpers/paramsGa4';
import { firstTry } from '../../shared/helpers/numberTry';
import { AnalyticsGA4Service } from '../../services/analyticsGA4.service';
import { NavigationEntryService } from '../../services/navigationEntry.service';
import { BottomSheet } from '../../shared/components/bottom-sheet/bottom-sheet.component';
import { RoutesPages } from '../../interfaces/routes';
import { verifyDeviceInfoOfAgent } from '../../shared/helpers/verification';
import { backButtonBlock } from '../../shared/helpers/backButtonBrowser';
import { Subject } from 'rxjs';

declare function initApp();

@Component({
  selector: 'app-aware-root',
  templateUrl: './aware.component.html',
  styleUrls: ['./aware.component.scss'],
})
export class AwareComponent {
  initPage: boolean = true;
  finishPageOne: boolean = false;
  finishPageTwo: boolean = false;
  routePage = RoutesPages.Aware;
  @ViewChild('elementFocus') elementFocus: ElementRef;
  @ViewChild(BottomSheet) BottomSheet: any;
  private unsubscriber: Subject<void> = new Subject<void>();
  BottomSheetCall = {
    callBottomSheet: () => {
      this.BottomSheet.callBottomSheet();
    }
  };

  constructor(
    public endFactory: EndFactoryService,
    public route: ActivatedRoute,
    private analyticsGA4Service: AnalyticsGA4Service,
    public navigationEntry: NavigationEntryService,
  ) { }

  ngOnInit() {
    this.navigationEntry.verifyNavigationEntry();
    backButtonBlock(this.unsubscriber, this.BottomSheetCall.callBottomSheet.bind(this));
    this.adjustFormatScreenInit();
    if (this.route?.snapshot?.queryParams?.restart) {
      initApp();
    }
    setTimeout(() => {
      this.initPage = false
    }, 5000);
    this.analyticsGA4Service.trackEventGA4(AWARE_PAGE);
    firstTry();
  }

  ngAfterViewInit() {
    this.monitoringElement();
    setTimeout(() => {
      this.elementFocus.nativeElement.focus();
    }, 5000);
    // this.monitoringResult();
  }

  ngOnDestroy(): void {
    this.adjustFormatScreenEnd();
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  clickTakePicture() {
    this.analyticsGA4Service.trackEventGA4(AWARE_CLICK_TAKE_PICTURE);
  }

  monitoringElement() {
    const element = document.getElementById('speech-progress');
    if (element) {
      this.updateVisibility(element);

      const observer = new MutationObserver(() => {
        this.updateVisibility(element);
      })

      observer.observe(element, { attributes: true, attributeFilter: ['aria-valuenow'] });
    }
  }

  // PROVAVELMENTE NAO SERÄ MAIS NECESSARIO PELO USO Do this.factory.resolveFactory(JSON.parse(res)); NO NEW CHARON

  // monitoringResult() {
  //   const element = document.getElementById('frontImage');
  //   if (element) {
  //     const observer = new MutationObserver(() => {
  //       this.callResolve(element)
  //     })
  //     observer.observe(element, { attributes: true, attributeFilter: ['aria-response'] });
  //   }
  // }

  // callResolve(element: HTMLElement) {
  //   const result = element.getAttribute('aria-response');
  //   if(result) {
  //     setTimeout(() => {
  //       this.endFactory.resolveFactory(JSON.parse(result));
  //     }, 5000);
  //   }
  // }

  updateVisibility(element: HTMLElement) {
    const finishPageOne = element.getAttribute('aria-valuenow');
    if (finishPageOne) {
      if (parseInt(finishPageOne, 10) === 100) {
        this.finishPageOne = true;
        this.analyticsGA4Service.trackEventGA4(LOADING_ANALYSE);
        setTimeout(() => {
          this.finishPageOne = false;
          this.finishPageTwo = true;
          this.analyticsGA4Service.trackEventGA4(LOADING_MEASURING);
        }, 2000);
      }
    }
  }

  adjustFormatScreenInit() {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  }

  adjustFormatScreenEnd() {
    document.body.style.overflow = 'initial';
    document.body.style.position = 'initial';
    document.body.style.width = 'initial';
  }

  callButtomSheet() {
    this.BottomSheet.callBottomSheet();
  }

  verifyIsSafari() {
    const { browser } = verifyDeviceInfoOfAgent();
    return browser === 'Safari';
  }

}
