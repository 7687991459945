export const facetecLabels = {
  FaceTec_action_ok: 'OK',
  FaceTec_action_im_ready: 'Começar',
  FaceTec_action_try_again: 'Tentar novamente',
  FaceTec_action_continue: 'CONTINUAR',
  FaceTec_action_take_photo: 'TIRAR FOTO',
  FaceTec_action_accept_photo: 'ACEITAR',
  FaceTec_action_retake_photo: 'VOLTAR',
  FaceTec_action_confirm: 'CONFIRMAR INFORMAÇÕES',
  FaceTec_accessibility_cancel_button: 'Cancelar',
  FaceTec_accessibility_tap_guidance: 'Toque duas vezes em qualquer lugar na tela para orientação de alinhamento facial.',
  FaceTec_accessibility_key_down_guidance: 'Pressione enter ou a barra de espaço para orientação de alinhamento facial',
  FaceTec_accessibility_feedback_move_phone_away: 'Rosto muito próximo',
  FaceTec_accessibility_feedback_move_phone_closer: 'Rosto muito longe',
  FaceTec_accessibility_feedback_face_too_far_left: 'Rosto muito à esquerda',
  FaceTec_accessibility_feedback_face_too_far_right: 'Rosto muito à direita',
  FaceTec_accessibility_feedback_face_too_low: 'Rosto muito baixo',
  FaceTec_accessibility_feedback_face_too_high: 'Rosto muito alto',
  FaceTec_accessibility_feedback_face_rotated_too_far_left: 'Rosto muito virado para a esquerda',
  FaceTec_accessibility_feedback_face_rotated_too_far_right: 'Rosto muito virado para a direita',
  FaceTec_accessibility_feedback_face_looking_too_far_left: 'Rosto apontando muito para a esquerda',
  FaceTec_accessibility_feedback_face_looking_too_far_right: 'Rosto apontando muito para a direita',
  FaceTec_accessibility_feedback_face_not_in_camera: 'Rosto Fora da câmera',
  FaceTec_accessibility_feedback_hold_phone_to_eye_level: 'Segure o Dispositivo ao Nível dos Olhos',
  FaceTec_accessibility_feedback_move_away_web: 'Câmera muito Próxima',
  FaceTec_accessibility_feedback_move_closer_web: 'Câmera muito longe',
  FaceTec_accessibility_feedback_hold_to_eye_level_web: 'Mover la cámara al nivel de los ojos',
  FaceTec_presession_frame_your_face: 'Enquadre seu rosto',
  FaceTec_presession_look_straight_ahead: 'Olhe para frente',
  FaceTec_presession_hold_steady3: 'Mantenha a posição por: 3',
  FaceTec_presession_hold_steady2: 'Mantenha a posição por: 2',
  FaceTec_presession_hold_steady1: 'Mantenha a posição por: 1',
  FaceTec_presession_eyes_straight_ahead: 'Olhe para frente',
  FaceTec_presession_remove_dark_glasses: 'Remova os óculos escuros',
  FaceTec_presession_neutral_expression: 'Expressão neutra, sem sorriso',
  FaceTec_presession_conditions_too_bright: 'Condições muito iluminadas',
  FaceTec_presession_brighten_your_environment: 'Ilumine seu Ambiente',
  FaceTec_feedback_center_face: 'Centralize seu rosto',
  FaceTec_feedback_face_not_found: 'Enquadre seu rosto',
  FaceTec_feedback_move_phone_away: 'Afaste-se',
  FaceTec_feedback_move_away_web: 'Afaste-se',
  FaceTec_feedback_move_phone_closer: 'Aproxime-se',
  FaceTec_feedback_move_web_closer: 'Aproxime o rosto e preencha o Oval',
  FaceTec_feedback_move_web_even_closer: 'Ainda mais perto',
  FaceTec_feedback_move_phone_to_eye_level: 'Mova a câmera para o Nível Dos Olhos',
  FaceTec_feedback_move_to_eye_level_web: 'Olhe Direto para a câmera',
  FaceTec_feedback_face_not_looking_straight_ahead: 'Olhe para Frente',
  FaceTec_feedback_face_not_upright: 'Mantenha Sua Cabeça Reta',
  FaceTec_feedback_face_not_upright_mobile: 'Mantenha a Cabeça Reta',
  FaceTec_feedback_hold_steady: 'Mantenha a posição',
  FaceTec_feedback_use_even_lighting: 'Ilumine o rosto de maneira mais uniforme',
  FaceTec_instructions_header_ready_desktop: 'Prepare-se para começar',
  FaceTec_instructions_header_ready_1_mobile: 'Prepare-se para',
  FaceTec_instructions_header_ready_2_mobile: 'começar',
  FaceTec_instructions_message_ready_desktop: 'Posicione seu rosto e siga as orientações',
  FaceTec_instructions_message_ready_1_mobile: 'Posicione seu rosto e siga as orientações',
  FaceTec_instructions_message_ready_2_mobile: '',
  FaceTec_retry_header: 'Algo deu errado com sua foto',
  FaceTec_retry_subheader_message: 'Vamos tentar novamente!',
  FaceTec_retry_your_image_label: 'sua foto',
  FaceTec_retry_ideal_image_label: 'exemplo',
  FaceTec_retry_instruction_message_1: 'Sem reflexo ou iluminação extrema',
  FaceTec_retry_instruction_message_2: 'Expressão neutra, sem sorriso',
  FaceTec_retry_instruction_message_3: 'Muito Embaçado, limpe a câmera',
  FaceTec_camera_feed_issue_header: 'Problema Protegendo O Feed Da câmera',
  FaceTec_camera_feed_issue_header_mobile: 'Problema protegendo<br/>O Feed Da câmera',
  FaceTec_camera_feed_issue_subheader_message_mobile: 'Este aplicativo bloqueia configurações suspeitas de webcam.<br/><a href=\'https://livenesscheckhelp.com/\' target=\'_blank\' style=\'text-decoration:underline; pointer-events:all;\'>Saiba mais aqui.</a>.',
  FaceTec_camera_feed_issue_subheader_message: 'Este sistema não pode ser verificado devido ao seguinte:',
  FaceTec_camera_feed_issue_table_header_1: 'Possível Problema',
  FaceTec_camera_feed_issue_table_header_2: 'Corrigir',
  FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error: 'Permissões de câmera não lembradas no Firefox.',
  FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error: 'Marque Lembrar Permissões.',
  FaceTec_camera_feed_issue_table_row_1_cell_1: 'Câmera já em uso por outro App..',
  FaceTec_camera_feed_issue_table_row_1_cell_2: 'Feche o outro Aplicativo.',
  FaceTec_camera_feed_issue_table_row_2_cell_1: 'Um aplicativo 3rd-Party está modificando o vídeo.',
  FaceTec_camera_feed_issue_table_row_2_cell_2: 'Feche / desinstale o outro aplicativo.',
  FaceTec_camera_feed_issue_table_row_3_cell_1: 'Hardware não capaz de ser protegido.',
  FaceTec_camera_feed_issue_table_row_3_cell_2: 'Use um dispositivo diferente.',
  FaceTec_camera_feed_issue_subtable_message: 'Este aplicativo bloqueia configurações suspeitas de webcam. <a href=\'https://livenesscheckhelp.com/\' target=\'_blank\' style=\'text-decoration:underline; pointer-events:all;\'>Saiba mais aqui.</a>.',
  FaceTec_camera_feed_issue_action: 'TIRAR',
  FaceTec_camera_feed_issue_action_firefox_permissions_error: 'OK',
  FaceTec_camera_permission_header: 'Ative a câmera',
  FaceTec_camera_permission_message: 'Permissões de câmera desativadas.<br/>Verifique as configurações do seu sistema operacional e do navegador',
  FaceTec_enter_fullscreen_header: 'Modo Selfie Em Tela Cheia',
  FaceTec_enter_fullscreen_message: 'Antes de começarmos, clique no botão abaixo para abrir o modo de tela cheia.',
  FaceTec_enter_fullscreen_action: 'ABRIR TELA CHEIA',
  FaceTec_initializing_camera: 'Inicializando câmera',
  FaceTec_idscan_type_selection_header: 'Prepare-se para escanear<br/>seu Documento de identificação',
  FaceTec_idscan_capture_id_front_instruction_message: 'Mostre a frente da identificação',
  FaceTec_idscan_capture_id_back_instruction_message: 'Mostre o verso da identificação',
  FaceTec_idscan_review_id_front_instruction_message: 'Confirme se nítida e legível',
  FaceTec_idscan_review_id_back_instruction_message: 'Confirme se nítida e legível',
  FaceTec_idscan_additional_review_message: 'Revisão adicional<br/>Necessária',
  FaceTec_idscan_ocr_confirmation_main_header: 'Revise e confirme',
  FaceTec_idscan_ocr_confirmation_scroll_message: 'Role para baixo',
  FaceTec_idscan_feedback_flip_id_to_back_message: 'Mostre o Verso da identificação',
  FaceTec_result_success_message: 'Sucesso',
  FaceTec_result_facescan_upload_message: 'estamos enviando sua foto',
  FaceTec_result_idscan_upload_message: 'Carregando<br/>Escaneamento de identificação<br/>Criptografado',
  FaceTec_result_idscan_unsuccess_message: 'A foto da identificação<br/>não correspondeu<br/>ao rosto do usuário',
  FaceTec_result_idscan_success_front_side_message: 'Escaneamento da identificação concluído',
  FaceTec_result_idscan_success_front_side_back_next_message: 'Frente da identificação<br/>Escaneada',
  FaceTec_result_idscan_success_back_side_message: 'Escaneamento da identificação concluído',
  FaceTec_result_idscan_success_passport_message: 'Escaneamento de Passaporte concluído',
  FaceTec_result_idscan_success_user_confirmation_message: 'Escaneamento da identificação com foto<br/>concluído',
  FaceTec_result_idscan_success_additional_review_message: 'Captura de foto da identificação<br/>concluída',
  FaceTec_result_idscan_retry_face_did_not_match_message: 'O rosto não correspondeu<br/>muito Bem',
  FaceTec_result_idscan_retry_id_not_fully_visible_message: 'O Documento de identificação<br/>não está totalmente visível',
  FaceTec_result_idscan_retry_ocr_results_not_good_enough_message: 'O Texto da identificação não está legível',
  FaceTec_result_idscan_retry_id_type_not_suppported_message: 'Tipo de identificação não Suportado<br/>Use Uma identificação diferente',
  FaceTec_result_idscan_retry_barcode_not_read_message: 'Falha ao escanear o código de barras<br/>Por Favor, Tente Novamente',
}