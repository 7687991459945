import { Injectable } from "@angular/core";
import { CharonService } from '../services/charon.service';
import { MainService } from '../services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { verifyRoute } from '../shared/helpers/verification';
import { CoreService } from '../services/core.service';
import { Supplier } from '../interfaces/Suppliers';
import { FacetecService } from '../services/facetec.service';
import { ResponseError, TypesMessages } from '../interfaces/ErrorsServices';
import { AwareService } from '../services/aware.service';
import { saveTokens, saveUrlData } from '../shared/helpers/dataAggregator';
import { LOGS_START_AWARE, LOGS_START_FACETEC } from '../shared/helpers/stepsToLogs';
import { datadogSetNumberTry, datadogSetSupplier, datadogSetUser, datadogSetErrorType, datadogSetFacetecInstalationID } from '../shared/helpers/datadog';
import { LocalStorage, SessionStorage } from "../interfaces/dataStorage";
import { RoutesPages } from '../interfaces/routes';
import { ErrorsPage } from '../interfaces/ErrorsPage';

@Injectable({
	providedIn: 'root'
})
export class InitFactoryService {
	inicializationStatus: string = TypesMessages.SUCCESS;
	showButton: boolean = true;
	showLoading: boolean = false;

	constructor(
		public caronteService: CharonService,
		public mainService: MainService,
		private activatedRoute: ActivatedRoute,
		public coreService: CoreService,
		public facetecService: FacetecService,
		public awareService: AwareService,
		public router: Router,
	) { }

	async initializeFactory(): Promise<string> {
		// Caronte Legado, será removido na Bio 2.0
		await this.caronteService.carontLinks();

		// Caronte novo, inicio do fluxo.
		const status = await this.mainService.carontLinks();

		if (status === 200) {
			this.inicializationStatus = await this.initSession();
		} else {
			this.inicializationStatus = TypesMessages.CW_ERRO_CREATE_SESSION_HTTP_404;
		}

		return this.inicializationStatus;
	}

	async initSession(): Promise<string> {
		let inicializationStatus = this.inicializationStatus;

		verifyRoute(this.activatedRoute);

		const captureKey = localStorage.getItem(LocalStorage.CaptureKey);
		const carontSession = localStorage.getItem(LocalStorage.CarontSessionLink);
		const session_link = localStorage.getItem(LocalStorage.SessionLink) || '';

		datadogSetUser();

		const callbackInitSession = (response: any) => {
			// Valores buscados no caronte antigo, será removido na Bio 2.0
			const supplier = response.supplier;
			const token = response.token;
			const keyLicense = response.keyLicense;
			const dateExpiryKey = response.dateExpiryKey;
			const tokenLiveness = response.tokenLiveness;

			// Fluxo Bio 2.0
			// Valores buscados no Caronte Bio 2.0
			// Lembrando que os labels podem mudar pois hj o back está apenas mocado
			// const supplier = response?.fornecedor?.nome;
			// const token = response?.fornecedor?.parametros?.token; 
			// const keyLicense = response?.parametros?.licenca;
			// const dateExpiryKey = response?.parametros?.dateExpiryKey;
			// const tokenLiveness = response?.parametros?.tokenLiveness;
			// const publicKey = response?.parametros?.chave;
			// const deviceKey = response?.parametros?.chave_dispositivo;
			// this.initServices(supplier, keyLicense, dateExpiryKey, tokenLiveness, publicKey, deviceKey);

			// Chamada sem os parametros recebidos do backend, será removido na Bio 2.0
			this.initServices(supplier, keyLicense, dateExpiryKey, tokenLiveness);
			saveTokens(supplier, token);

			saveUrlData();
			this.getButtonStatus();
			this.mainService.togglesAPI();
		}

		const errorCallback = (error: ResponseError) => {
			if (error.error) inicializationStatus = error.error.message;
		}

		await this.coreService.sendParams(session_link, { captureKey: captureKey }, { carontSession: carontSession }, callbackInitSession, errorCallback);

		// Fluxo Bio 2.0
		// await this.mainService.parametersAPI(callbackInitSession, errorCallback);

		return inicializationStatus;
	}

	async initServices(supplier: string, keyLicense: string, dateExpiryKey: string, tokenLiveness: string, publicKey?: string, deviceKey?: string): Promise<void> {
		if (supplier === Supplier.FACETEC) {
			localStorage.setItem(LocalStorage.NumberTry, '0');
			await this.facetecService.initFacetec(
				keyLicense,
				dateExpiryKey,
				tokenLiveness,
				publicKey,
				deviceKey
			);
		}
		datadogSetNumberTry();
		datadogSetSupplier(supplier);
	}

	getButtonStatus() {
		const supplier = sessionStorage.getItem(SessionStorage.Supplier);
		switch (supplier) {
			case Supplier.FACETEC:
				let startButtonLoad = setInterval(() => {
					if (!this.facetecService.showButton) {
						this.showButton = false;
						clearInterval(startButtonLoad);
					}
				}, 1000);
				break;
			case Supplier.AWARE:
				this.showButton = false;
				break;
			default:
				this.showButton = false;
				datadogSetErrorType(ErrorsPage.NO_SUPPLIER);
				break;
		}
	}

	async startCam(isNewTry?: boolean): Promise<void> {
		const supplier = sessionStorage.getItem(SessionStorage.Supplier);
		switch (supplier) {
			case Supplier.FACETEC:
				this.getFacetecLoadingStatus();
				if (localStorage.getItem(LocalStorage.ToggleLogs) === 'true') {
					this.mainService.insightsAPI(LOGS_START_FACETEC);
				}
				datadogSetFacetecInstalationID();
				this.router.navigate([RoutesPages.Facetec]);
				break;
			case Supplier.AWARE:
				if (isNewTry !== true) {
					this.awareService.initAware();
					this.showLoading = false;
					this.router.navigate([RoutesPages.Aware]);
				} else {
					this.router.navigate([RoutesPages.Aware], { queryParams: { restart: true } });
				}
				if (localStorage.getItem(LocalStorage.ToggleLogs) === 'true') {
					this.mainService.insightsAPI(LOGS_START_AWARE);
				}
				break;
			default:
				datadogSetErrorType(ErrorsPage.NO_SUPPLIER);
				break;
		}
	}

	getFacetecLoadingStatus() {
		this.showLoading = true;
		let loading = setInterval(() => {
			if (!this.facetecService.loading) {
				clearInterval(loading);
				this.showLoading = false;
			}
		}, 1000);
	}

}