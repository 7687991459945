import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AwareService {

  async initAware() {
    this.loadScript('assets/dist/aware/KnomiWeb.js').then(() => { }).catch((error) => {
      console.error('Error to load file ', error)
    });
    this.loadScript('assets/dist/aware/webrtc-adapter/adapter.js').then(() => { }).catch((error) => {
      console.error('Error to load file ', error)
    });
  }

  loadScript(url: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = url;
      scriptElement.onload = resolve;
      scriptElement.onerror = reject;
      document.body.appendChild(scriptElement);
    });
  }
}