<loading *ngIf="initPage" [showFooter]="false"></loading>

<div class="containerButton">
	<button id="captureBtn" type="button" #elementFocus>tirar minha foto</button>
</div>

<div id="previewSection">
	<div>
		<div id="feedbackSection">
			<input id="feedbackText" readonly="readonly" />
		</div>
		<div class="progress">
			<div id="speech-progress" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
		</div>
	</div>
	<div>
		<video id="previewWindow" class="mirrorVideo" playsinline autoplay muted>
			Your browser does not support the video tag.
		</video>
		<canvas id="ovalOverlay"></canvas>
		<img id="frontImage" alt src="" aria-response="{}" />
	</div>
</div>

<loading *ngIf="this.finishPageOne" icon="maquina_fotografica_outline" label="Analisando sua foto" [showFooter]="true">
</loading>
<loading *ngIf="this.finishPageTwo" icon="maquina_fotografica_outline"
	label="Medindo pontos do seu rosto com nossa tecnologia" [showFooter]="true"></loading>