<div class="container-page">
  <div class="header-page">
    <button id="back-button" idsMobileHeaderAction idsIconButton variant="neutral" title="fechar" aria-label="fechar"
      (click)="callRedirect()">
      fechar
    </button>
  </div>
  <div class="content-page">
    <ids-icon class="icon-page" size="L" variant="error">{{icon}}</ids-icon>
    <h1 class="ids-title -small" tabindex="-1" id="main-title">{{title}}</h1>
    <span class="ids-overline-text --bold">{{description}}</span>
  </div>
  <div class="button-page">
    <button idsMainButton (click)="handleButton()" full>{{button}}</button>
  </div>
</div>