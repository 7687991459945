import { NgModule } from '@angular/core';
import { Loading } from './loading/loading.component';
import { CommonModule } from '@angular/common';
import { IdsModule } from '../shared.module';

const components = [
  Loading
]

@NgModule({
  declarations: [
    components,
  ],
  imports: [
    CommonModule,
    IdsModule,
  ],
  exports: [
    components
  ]
})
export class ComponentsModule { }