export const helpPage = [
  {
    question: "O que é a biometria facial?",
    answer: "Uma tecnologia que identifica traços específicos do rosto de uma pessoa. A biometria facial é um código gerado a partir da identificação desses traços. Ela é capaz de diferenciar uma pessoa da outra ao analisar os códigos, que são individuais, e compará-los.",
  },
  {
    question: "O que acontece com a minha foto?",
    answer: "A sua foto passa pelo processo de identificação de traços no rosto. O sistema de segurança analisa e, de forma matemática, pode validar que você é você mesmo :)",
  },
  {
    question: "Com quem minha foto pode ser compartilhada?",
    answer: "Validamos seu reconhecimento facial em sistema eletrônicos e, quando necessário, com terceiros e IDTechs, empresas especializadas em tecnologias de  validação de biometria facial.",
  },
  {
    question: "Por que é necessário o reconhecimento facial?",
    answer: "Porque a biometria facial é uma tecnologia que garante a sua segurança e pode prevenir fraudes.",
  },
  {
    question: "A biometria facial é segura?",
    answer: "Sim! Essa tecnologia é usada justamente como um mecanismo de prevenção a fraudes.",
  },
  {
    question: "A biometria facial funciona em qualquer celular?",
    answer: "Não em todos! Ela está disponível em grande parte dos aparelhos celulares do Brasil e não depende do FaceID. Para ter a biometria facial é preciso ter uma câmera em bom estado de funcionamento. No sistema iOS, todos os aparelhos a partir do modelo 5S têm acesso à biometria facial, menos o iPhone 5C. Já no Android, a partir do Android 5.",
  },
  {
    question: "Qual a diferença entre biometria facial e faceID?",
    answer: "O FaceID do seu aparelho celular é um sensor de reconhecimento facial que pode ser usado em vários aplicativos, de acordo com o fabricante e o modelo. Não temos acesso a essas informações. Já a biometria facial é diferente: é uma solução existente para algumas funções do aplicativo.",
  },
]
