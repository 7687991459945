import { environment } from '../../../environments/environment';

declare const CryptoJS: any;
declare const JSEncrypt: any;

export interface IEncrypted {
  payload: string;
  key: string;
	error?: string;
}

export async function encryptRsa(message: string, publicKey: string): Promise<string> {
  const encrypt_vr = new JSEncrypt();
  encrypt_vr.setPublicKey(publicKey);
  return encrypt_vr.encrypt(message);
}

export const encryptAes = (text: string, aesKey: string) => CryptoJS.AES.encrypt(text, aesKey);

export function randomText(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const encrypt = async (payload: string): Promise<IEncrypted> => {
  const aesKey = randomText(16);
  const rsaKey = environment.publicKey;
  const encryptedPayload = encryptAes(payload, aesKey);
  const encryptedKey = await encryptRsa(aesKey, rsaKey);
  return { payload: encryptedPayload.toString(), key: encryptedKey };
};

export const setCript = async (captureKey: any): Promise<IEncrypted> => {
	if (environment.disableCripto) {
		return {
			payload: captureKey,
			key: '',
		};
	}
	try {
		return encrypt(JSON.stringify(captureKey));
	} catch (e) {
		return {
			error: e,
			payload: '',
			key: '',
		};
	}
}

export const generateApiKey = (length: number) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}