
export class InitFactoryMock {
  public showButton: boolean = false;
  public showLoading: boolean = false;
	constructor() { }

	async initializeFactory(): Promise<string> {
		return new Promise((resolve) => {
			resolve("success")
		})
	}

	async initSession(): Promise<any> {
		return new Promise((resolve) => {
			resolve(true)
		})
	}

	saveTokens(response): Promise<any> {
		return new Promise((resolve) => {
			resolve(true)
		})
	}

	initServices(response): Promise<any> {
		return new Promise((resolve) => {
			resolve(true)
		})
	}


	startCam(): Promise<any> {
		return new Promise((resolve) => {
			resolve(true)
		})
	}
}
