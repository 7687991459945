import { Component, ViewChild } from '@angular/core';
import { firstTry } from '../../shared/helpers/numberTry';
import { FacetecService } from '../../services/facetec.service';
import { AnalyticsGA4Service } from '../../services/analyticsGA4.service';
import { FACETEC_PAGE } from '../../shared/helpers/paramsGa4';
import { NavigationEntryService } from '../../services/navigationEntry.service';
import { RoutesPages } from '../../interfaces/routes';
import { BottomSheet } from '../../shared/components/bottom-sheet/bottom-sheet.component';
import { Subject } from 'rxjs';
import { backButtonBlock } from '../../shared/helpers/backButtonBrowser';

@Component({
  selector: 'app-facetec',
  templateUrl: './facetec.component.html',
  styleUrls: ['./facetec.component.scss'],
})
export class FacetecComponent {
  routePage = RoutesPages.Facetec;
  @ViewChild(BottomSheet) BottomSheet: any;
  private unsubscriber: Subject<void> = new Subject<void>();
  BottomSheetCall = {
    callBottomSheet: () => {
      this.BottomSheet.callBottomSheet();
    }
  };

  constructor(
    public facetecService: FacetecService,
    private analyticsGA4Service: AnalyticsGA4Service,
    public navigationEntry: NavigationEntryService,
  ) { }

  ngOnInit() {
    this.navigationEntry.verifyNavigationEntry();
    backButtonBlock(this.unsubscriber, this.BottomSheetCall.callBottomSheet.bind(this));
    firstTry();
    this.facetecService.startCam();
    this.analyticsGA4Service.trackEventGA4(FACETEC_PAGE);
  }

  ngOnDestroy() {
    const facetecComponent = document.getElementById("DOM_FT_PRIMARY_TOPLEVEL_mainContainer")
    if (facetecComponent) {
      facetecComponent!.remove();
    }
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  callButtomSheet() {
    this.BottomSheet.callBottomSheet();
  }
}